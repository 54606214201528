const styles = {
  control: (provided) => ({
    ...provided,
    height: 41,
    border: 0,
    boxShadow: 'none',
    background: '#f8f8f8',
    borderRadius: '5px',
    cursor: 'pointer'
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    border: '1px solid #e8e8e8',
    background: '#fff'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 15px'
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: '14px'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  option: (provided, state) => ({
    ...provided,
    ':hover': {
      background: '#e8e8e8'
    },
    ':active': {
      background: '#e8e8e8'
    },
    background: state.isSelected ? '#e8e8e8' : state.isFocused ? '#e8e8e8' : '#fff',
    fontSize: '14px',
    fontWeight: state.isSelected ? '600' : '400',
    color: '#2a2e43'
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px'
  })
}

export default styles
