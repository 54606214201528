import React from "react";

import "./Checkbox.sass";

export default ({ label, description, children, ...props }) => {
  return (
    <label className="label-checkbox" style={props.disabled ? {cursor: 'not-allowed'} : null}>
      <div>
        <input {...props} className="check-box-primary ">
          {children}
        </input>
        <span className="label-text" />
      </div>

      <div className="label-description">
        <p>{label}</p>
        {description && (<small>{description}</small>)}
      </div>
    </label>
  );
};
