export function parsePreviewUrl(file) {
  let url;
  if (file instanceof File) {
      url = URL.createObjectURL(file);
  } else {
      url = file;
  }
  return url;
}

export function getFileType(fileName) {
  const array = fileName.split('.');
  return array[array.length - 1];
}
