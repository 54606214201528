import React from "react"

import GlobalStyle from "./styles/global";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { Router } from "react-router-dom"

import { ToastContainer, Slide } from 'react-toastify';
import history from "./services/history";
import Routes from "./routes"

function App() {
  return (
    <Router history={history}>
      <Routes />
      <GlobalStyle />
      <ToastContainer autoClose={3000} draggable transition={Slide} />
    </Router>
  );
}

export default App;
