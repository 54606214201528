import React from "react";
import "./Header.sass";
import Logo from "./Logo";
import { Container, Navbar, NavbarBrand } from "react-bootstrap";
const Header = () => {
  return (
    <header>
      <Container className="header">
        <Navbar expand="md">
          <NavbarBrand href="/">
            <Logo />
          </NavbarBrand>
        </Navbar>
        <div className="content-title">
          <h1>Olá cooperado(a), seja bem-vindo(a)!</h1>
          <h5>Estamos felizes em receber a sua inscrição para Delegado.</h5>
          <br />
          <p>
            <b>CRITÉRIOS PARA SER DELEGADO:</b>
          </p>
          <br />
          <ul>
            <li>Ter mais de 16 anos;</li>
            <li>Estar habilitado para votar;</li>
            <li>Ser cooperado há, no mínimo, 1 ano;</li>
            <li>Não ser colaborador da Central Ailos;</li>
            <li>
              Não ser cônjuge ou parente dos dirigentes, colaboradores da Cooperativa e da Cooperativa Central;
            </li>
            <li>Não ser colaborador da Cooperativa e Cooperativa Central;</li>
            <li>
              Não ocupar cargo político-partidário nos anos de 2023 e 2024;
            </li>
            <li>
              Não ser colaborador ou dirigente da Cooperativa nos anos de 2023 e 2024;
            </li>
            <li>
              Em caso de empate, será eleito o cooperado que possui mais tempo
              de associado;
            </li>
            <li>
              Estar em dia com suas obrigações e não ter ação judicial contra a
              Cooperativa.
            </li>
          </ul>
          <br />
          <p>
            A Cooperativa fará a validação das informações conforme os critérios
            e em breve entrará em contato com você.
          </p>
        </div>
      </Container>
    </header>
  );
};

export default Header;
