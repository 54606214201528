import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Checkbox from "./../Checkbox/";
import api from "../../services/api";
import AsyncSelect from "react-select/async";
import selectStyles from "./selectStyles";
import { parsePreviewUrl, getFileType } from "../../services/utils";
import "./Form.sass";
import api_url from "../../services/api_url";

const DelegateForm = ({ setSubmitted }) => {
  const inputFile = useRef(null);
  const [queryString, setQueryString] = useState("");
  const [requestInitialOptions, setRequestInitialOptions] = useState(true);
  const [submit, setSubmit] = useState(true);
  const [coops, setCoops] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState({
    document_number: "",
    phone: "",
    email: "",
    branch: "",
    occupation: "",
    currentYear: "",
  });
  const [photo, setPhoto] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    fetchCoops();
  }, []);
  async function fetchCoops() {
    const { data } = await api.get("v1/coops");
    setCoops(data);
  }
  function updateFields(event) {
    const data = { ...fields };
    data[event.target.name] = event.target.value;
    setFields(data);
  }

  function handleSelect(value) {
    setFields({
      ...fields,
      occupation: value,
    });
  }
  function fetchOccupation() {
    let params = [];
    let url = `${api_url}/v1/occupations`;

    if (requestInitialOptions) {
      params.push("limit=100");
    }

    if (queryString) {
      params.push(`name=${queryString}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return fetch(url)
      .then((res) => res.json())
      .then((occupations) =>
        occupations.map(({ name }) => {
          const ocupationName = `${name}`;

          return {
            label: ocupationName,
          };
        })
      )
      .finally(() => {
        if (requestInitialOptions) {
          setRequestInitialOptions(false);
        }
      });
  }
  function checkFields(fields) {
    const keys = Object.keys(fields);

    keys.forEach((key) => {
      if (!fields[key]) {
        switch (key) {
          case "document_number":
            toast.error("Por favor, preencha o documento");
            break;
          case "phone":
            toast.error("Por favor, preencha o telefone");
            break;
          case "email":
            toast.error("Por favor, preencha o email");
            break;
          case "branch":
            toast.error("Por favor, selecione a cooperativa");
            break;
          default:
            break;
        }
        setSubmit(false);
        return;
      }
    });
    return;
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      // checkFields(fields);
      setSubmitting(true);

      if (submit) {
        let body = {
          ...fields,
        };
        body.document_number = body.document_number.replace(/([^\d])+/gim, "");
        body.phone = body.phone.replace(/([^\d])+/gim, "");

        if (photo instanceof File) {
          try {
          const fileType = getFileType(photo.name);

          const { data } = await api.get(`/v1/file-upload?context=delegates&extension=${fileType}`);
          await axios.create().put(data.url, photo);

          const url = data.url.split('?')[0];

          setPhoto(url);
          body.photo = url;
          } catch (error) {
            setSubmitting(false);
            toast.error("Ops! Não conseguimos fazer o upload da sua foto. Tente novamente.");
            return;
          }
        } else {
          body.photo = photo;
        }

        await api.post("/v1/delegates", body);
        setSubmitted(true);
        return;
      }
      return;
    } catch (error) {
      toast.error(
        error.response.data.message || "Algo deu errado, tente mais tarde"
      );
    } finally {
      setSubmitting(false);
    }
  }

  function renderTextTerms() {
    let date = "";

    switch (fields.branch) {
      //VIACREDI
      case "1":
        date = "27/04/2021";
        break;
      //Evolua
      case "14":
        date = "08/04/2021";
        break;
      //Civia
      case "13":
        date = "29/04/2021";
        break;
      //Transpocred
      case "9":
        date = "22/04/2021";
        break;
      default:
        break;
    }

    return (
      <h6>
        {`*Que devo participar e apresentar na Assembleia Geral Ordinária
          Digital, que será no dia ${date}, os votos referentes às decisões
          tomadas na Assembleia de Grupo de Cooperados do qual faço parte;`}
      </h6>
    );
  }

  function handleUpload(event) {
    const types = ['jpg', 'jpeg', 'png'];
    const value = event.target.files[0];
    const fileType = getFileType(value.name);

    if (!fileType || !types.includes(fileType.toLowerCase())) {
      toast.error('Insira apenas imagens JPG, JPEG ou PNG');
      return
    }

    setPhoto(value);
  }

  return (
    <div className="form">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formGroupDocument">
        <div className="upload-input">
          <div className="upload-input-container">
            {photo && (
              <div
                className="upload-input-remove"
                onClick={() => {
                  inputFile.current.value = '';
                  setPhoto('')
                }}
                title="Remover"
              >
                <div className="icon-close" />
              </div>
            )}

            <label htmlFor="photo" title="Foto">
              {photo && <img src={parsePreviewUrl(photo)} alt="" />}

              <div className="upload-input-overflow">
                <div className="icon-photo-camera" />
              </div>
            </label>

            <input
              ref={inputFile}
              id="photo"
              type="file"
              name="photo"
              accept="image/*"
              onChange={handleUpload}
            />
          </div>
        </div>

          <Form.Label>CPF:</Form.Label>
          <InputMask
            mask="999.999.999-99"
            name="document_number"
            type="text"
            className="form-control"
            required
            value={fields.document_number}
            onChange={(e) => updateFields(e)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupPhone">
          <Form.Label>Cooperativa:</Form.Label>
          <Form.Control
            name="branch"
            as="select"
            required
            value={fields.branch}
            onChange={(e) => updateFields(e)}
          >
            <option selected value="" />
            {coops.map((coop) => {
              if (
                coop.name === "VIACREDI" ||
                coop.name === "EVOLUA" ||
                coop.name === "CIVIA" ||
                coop.name === "TRANSPOCRED"
              ) {
                return (
                  <option key={coop.code} value={coop.code}>
                    {coop.name}
                  </option>
                );
              } else {
                return null;
              }
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formGroupPhone">
          <Form.Label>Telefone:</Form.Label>
          <InputMask
            mask="(99) 99999-9999"
            name="phone"
            type="text"
            className="form-control"
            required
            value={fields.phone}
            onChange={(e) => updateFields(e)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            required
            value={fields.email}
            onChange={(e) => updateFields(e)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Profissão:</Form.Label>
          <AsyncSelect
            styles={selectStyles}
            value={fields.occupation}
            loadOptions={fetchOccupation}
            getOptionLabel={(occupation) => occupation.label}
            onInputChange={(value) => setQueryString(value)}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={handleSelect}
            noOptionsMessage={() => "Não encontramos essa profissão."}
            loadingMessage={() => "Buscando..."}
            placeholder="Digite sua profissão"
            defaultOptions
          />
        </Form.Group>
        <div style={{ marginBottom: "1rem" }}>
          <Checkbox
            type="checkbox"
            name="direct"
            Checkbox={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            label="Estou ciente das atribuições e responsabilidades dos Delegados:"
          />
        </div>
        <h6>
          {/* {renderTextTerms()} */}
          *Que devo participar na Assembleia Geral Ordinária e representar os
          votos referentes às decisões tomadas na Assembleia de Grupo de
          Cooperados do qual faço parte;
        </h6>
        <h6>
          *Caso constatado, a qualquer tempo pela Cooperativa, o não
          preenchimento dos critérios para o exercício do cargo de Delegado,
          serei automaticamente destituído do cargo;
        </h6>
        <h6>
          *De que as atividades desenvolvidas como Delegado do referido Grupo
          não dispõem qualquer tipo de vínculo trabalhista com a Cooperativa e
          Cooperativa Central;
        </h6>
        <br />
        <button type="submit" disabled={!checked || submitting}>
          Cadastrar
        </button>
      </Form>
    </div>
  );
};

export default DelegateForm;
