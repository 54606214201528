import React, { useState } from "react"
import { Container } from "react-bootstrap"

import "./Home.sass"

import Header from "../../components/Header"
import Form from "../../components/Form"
import Success from "../../components/Success"

const Home = () => {
    const [submitted, setSubmitted] = useState(false)
    return (
        <Container fluid={true}>
            <Header />
            <Container className="content">
                {submitted ? <Success /> : <Form setSubmitted={setSubmitted} />}
            </Container>
        </Container>
    )
}

export default Home